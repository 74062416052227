:root {
    --primaryColor: #fff;
    --primaryColorText: #4ba700;
    --bodyColorText: rgba(109, 108, 108, 255);
    --secondaryColor: #6c757d;
    --secondaryColorText: #fff;
    --borderColor: #ced4da;
    --bodyBackgroundColor: rgba(255, 255, 255, 1);
    --fontFamily: 'Poppins', "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    --fontFamilySize: 1rem;
    --fontFamilyWeight: 400;
    --logoWidth: 300px;
    --background: #4ba700;
    --loadingLogoWidth: 250px;
    --textSideBackgroundColor: #fff;
    --loadingBackgroundColor: #4ba700;
    --loginTextSide: 40%;
    --loginImageSide: 60%;
    --loginImageSideUrl: "";
    --loginTextSideDirection: row;

    /* Bootstrap variables */
    --bs-primary: var(--primaryColor) !important;
    --bs-secondary: var(--secondaryColor) !important;
    --bs-success: #198754 !important;
    --bs-info: #0dcaf0 !important;
    --bs-warning: #ffc107 !important;
    --bs-danger: #dc3545 !important;
    --bs-light: #f8f9fa !important;
    --bs-dark: #212529 !important;
    --bs-font-sans-serif: var(--fontFamily) !important;
    --bs-secondary-rgb: 108, 117, 125 !important;
    --bs-success-rgb: 25, 135, 84 !important;
    --bs-info-rgb: 13, 202, 240 !important;
    --bs-warning-rgb: 255, 193, 7 !important;
    --bs-danger-rgb: 220, 53, 69 !important;
    --bs-light-rgb: 248, 249, 250 !important;
    --bs-dark-rgb: 33, 37, 41 !important;
    --bs-white-rgb: 255, 255, 255 !important;
    --bs-black-rgb: 0, 0, 0 !important;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)) !important;
    --bs-body-font-family: var(--fontFamily) !important;
    --bs-body-font-size: var(--fontFamilySize) !important;
    --bs-body-font-weight: var(--fontFamilyWeight) !important;
    --bs-body-line-height: 1.5 !important;
    --bs-body-color: var(--bodyColorText) !important;
    --bs-body-bg: var(--bodyBackgroundColor) !important;
    --bs-border-width: 1px !important;
    --bs-border-style: solid !important;
    --bs-border-color: var(--borderColor) !important;
    --bs-border-color-translucent: var(--borderColor) !important;
    --bs-border-radius: 0.375rem !important;
    --bs-border-radius-sm: 0.25rem !important;
    --bs-border-radius-lg: 0.5rem !important;
    --bs-border-radius-xl: 1rem !important;
    --bs-border-radius-2xl: 2rem !important;
    --bs-border-radius-pill: 50rem !important;
    --bs-link-color: var(--bodyColorText) !important;
    --bs-link-hover-color: var(--primaryColorText) !important;
    --bs-code-color: #d63384 !important;
    --bs-highlight-bg: #fff3cd !important;
}

// BOOTSTRAP VARIABLES
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    --bs-gutter-x: 2.5rem !important;
}

.badge {
    border-radius: 15px;
    --bs-badge-font-weight: 500 !important;
    --bs-badge-padding-y: 0.5em !important;
}

.dropdown-item,
.dropdown-menu {
    --bs-card-bg: var(--bodyBackgroundColor) !important;
    --bs-dropdown-link-active-bg: var(--bodyColorTextHover) !important;
    --bs-dropdown-link-active-color: var(--bodyColorText) !important;
    --bs-dropdown-link-hover-bg: var(--bodyColorTextHover) !important;
    --bs-dropdown-link-hover-color: var(--bodyColorText) !important;
    --bs-dropdown-link-color: var(--bodyColorText) !important;
    --bs-dropdown-color: var(--bodyColorText) !important;
    --bs-dropdown-item-padding-y: 0.35rem;
}

.card {
    --bs-card-bg: var(--bodyBackgroundColor) !important;
    --bs-card-border-color: var(--borderColor) !important;
    --bs-card-spacer-y: 0.75rem;
    --bs-card-spacer-x: 0.75rem;
}

.pageHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
}

ol.breadcrumb {
    margin-bottom: 0rem;
}

li.breadcrumb-item {
    display: flex;
    margin-bottom: 0rem;
}

li.breadcrumb-item h5 {
    margin-bottom: 0rem;
}

.btn-primary {
    --bs-btn-color: var(--primaryColorText) !important;
    --bs-btn-bg: var(--primaryColor) !important;
    --bs-btn-border-color: var(--primaryColor) !important;
    --bs-btn-hover-color: var(--primaryColorText) !important;
    --bs-btn-hover-bg: var(--primaryColor) !important;
    --bs-btn-hover-border-color: var(--primaryColor) !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
    --bs-btn-active-color: var(--primaryColorText) !important;
    --bs-btn-active-bg: var(--primaryColor) !important;
    --bs-btn-active-border-color: var(--primaryColor) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--primaryColorText) !important;
    --bs-btn-disabled-bg: var(--primaryColor) !important;
    --bs-btn-disabled-border-color: var(--primaryColor) !important;
    border: 0px !important;
}

.bg-primary {
    background-color: var(--primaryColor) !important;
    color: var(--primaryColorText) !important;
}

.bg-outline-secondary {
    background-color: var(--secondaryColorText) !important;
    color: var(--secondaryColor);
    outline: 1px solid var(--secondaryColor);
}

.btn-secondary {
    --bs-btn-color: var(--secondaryColorText) !important;
    --bs-btn-bg: var(--secondaryColor) !important;
    --bs-btn-border-color: var(--secondaryColor) !important;
    --bs-btn-hover-color: var(--secondaryColorText) !important;
    --bs-btn-hover-bg: var(--secondaryColor) !important;
    --bs-btn-hover-border-color: var(--secondaryColor) !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
    --bs-btn-active-color: var(--secondaryColorText) !important;
    --bs-btn-active-bg: var(--secondaryColor) !important;
    --bs-btn-active-border-color: var(--secondaryColor) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--secondaryColorText) !important;
    --bs-btn-disabled-bg: var(--secondaryColor) !important;
    --bs-btn-disabled-border-color: var(--secondaryColor) !important;
    border: 0px !important;
}

.btn-outline-secondary {
    --bs-btn-color: var(--secondaryColor) !important;
    --bs-btn-border-color: var(--secondaryColor) !important;
    --bs-btn-active-color: var(--secondaryColorText) !important;
    --bs-btn-hover-bg: var(--secondaryColor) !important;
    --bs-btn-hover-border-color: var(--secondaryColor) !important;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: var(--secondaryColorText) !important;
    --bs-btn-active-bg: var(--secondaryColor) !important;
    --bs-btn-active-border-color: var(--secondaryColor) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--secondaryColor) !important;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--secondaryColor) !important;
    --bs-gradient: none;
}

.bg-secondary {
    background-color: var(--secondaryColor) !important;
}

.baseColorText {
    color: var(--bodyColorText) !important;
}

.baseBorderColor {
    border: 1px solid var(--bs-border-color);
}

.primaryColor {
    background-color: var(--primaryColor) !important;
}

.primaryColorText {
    color: var(--primaryColorText) !important;
}

.primaryColorT {
    color: var(--primaryColor) !important;
}

.pointer {
    cursor: pointer;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    --bs-gutter-y: 1.5rem;
    padding-top: calc(var(--bs-gutter-y) * 0.75);
    padding-bottom: calc(var(--bs-gutter-y) * 0.5);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link.focus-visible,
.nav-tabs .nav-item.focus-visible .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: var(--primaryColor);
    color: var(--primaryColorText);
}

// END BOOTSTRAP VARIABLES

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    font-family: var(--fontFamily);
    overflow: auto;
    height: 100%;
}

#root {
    height: 100%;
}

.App-start {
    position: relative;
    color: #fff;
    margin: auto;
    padding: 1.5rem;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-start-box {
    font-family: 'Poppins', "Segoe UI", "Helvetica Neue", Arial, sans-serif !important;
    max-width: 580px;
    --bs-bg-opacity: 0.2;
    padding: 1.5rem;
    border-radius: 10px;
    width: 100%;
    background-color: rgba(0 0 0 /0.2);
    overflow: auto;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15) !important;
}

.App-start-box .form-control {
    max-width: 250px !important;
    margin: auto;
}

.App-start .logo {
    max-width: 250px;
}

.loadingScreenProtection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}

.horizontally-centered {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.vertically-centered {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#tsparticles {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: fixed;
}

.loaderContainer {
    height: 100%;
    z-index: 1;
}

.main {
    overflow: auto;
    width: 100%;
    height: 100%;
}

.InitializedLayoutWrapper {
    background: var(--background) no-repeat center center / cover;
    display: flex;
    width: 100%;
    height: 100%;
}

.AuthenticatedLayoutWrapper {
    background: var(--background) no-repeat center center / cover;
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 70px;
}

.logo {
    max-width: var(--logoWidth);
    width: 100%;
    pointer-events: none;
}

.box {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.backButton {
    background-color: transparent;
    color: var(--bodyColorText);
    width: 35px !important;
    height: 35px !important;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
}

.iconsHover {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.truncate-multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

@media only screen and (max-width: 768px) {

    .AuthenticatedLayoutWrapper {
        height: calc(100% - 60px);
    }
}


/* Login */
.loginLoadingContainer {
    overflow: hidden;
    height: 100%;
    background: var(--loadingBackgroundColor);
}

.loginLoadingContainer .logo {
    max-width: var(--loadingLogoWidth) !important;
}

.loginLoadingContainer div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: var(--loginTextSideDirection);
    background: var(--textSideBackgroundColor);
    color: var(--textSideColor);
}

.loginContainer .textSide {
    flex: var(--loginTextSide);
    min-width: 580px;
}

.loginContainer .imageSide {
    flex: var(--loginImageSide);
    background-size: cover;
    background-position: center;
    background: var(--loginImageSideUrl) no-repeat center center / cover;
}

.loginContainer .boxWithFooter .showFooter {
    display: block;
}

.loginContainer .loginFooter {
    padding: 1rem 0rem;
    margin-top: auto;
    justify-content: center;
    text-align: center;
}

.loginContainer .boxWithFooter {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.loginContainer .boxWithFooter .showFooter {
    display: block;
}

.loginContainer .showFooter {
    height: 60px;
    width: 100%;
    display: none;
    padding-bottom: 0.5rem;
    text-align: center;
}

.loginContainer .box {
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 550px;
    margin: auto;
}

@media only screen and (max-width: 1050px) {
    .loginContainer .textSide {
        min-width: 385px;
    }

    .loginContainer .box {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .loginContainer .imageSide {
        display: none;
    }

    .loginContainer .box {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* Navbar */
.navbar {
    width: 100%;
    z-index: 101;
    position: fixed;
    display: flex;
    color: var(--navColorText);
    background: var(--navBackgroundColor) !important;
    padding: 0.5rem 1rem 0.5rem 1rem;
    height: 70px;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .15);

    .notificationCounter {
        position: absolute;
        top: 4px;
        left: 22px;
        display: inline-block;
        padding: 0 4px;
        min-width: 8px;
        max-width: 28px;
        height: 16px;
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
        border-radius: 22px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .userCard {
        width: 225px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .logo {
        pointer-events: unset !important;
        cursor: pointer;
        max-width: 150px;
        max-height: 45px;
    }

    .iconButton.active {
        background-color: var(--primaryColor) !important;
        color: var(--primaryColorText) !important;
    }

    .iconButton svg {
        font-size: 1.25em !important;
    }

    .iconButton {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        background-color: var(--transparent) !important;
        color: var(--navColorText) !important;
        box-shadow: none !important;

    }

    .dropdown-perfil button.active,
    .dropdown-perfil button.show,
    .dropdown-perfil button {
        background-color: transparent !important;
        color: transparent !important;
        box-shadow: none !important;
        padding: 0px;
    }

    .dropdown-toggle::after {
        display: none !important;
    }

    .dropdown-perfil .perfil {
        width: 45px;
        height: 45px;
        object-fit: cover;
        cursor: pointer;
        border: 1px solid var(--bs-border-color)
    }

    .dropdown-perfil .perfilxxl {
        width: 55px;
        height: 55px;
        object-fit: cover;
        cursor: pointer;
        border: 1px solid var(--bs-border-color)
    }

    .dropdown-perfil .userInfo {
        padding: 0rem 0.75rem; 
        width: calc(225px - 55px);
    }
    
    .dropdown-perfil .userInfo p {
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }
}

// Sidebar
.sidebar {
    width: 250px;
    min-width: 250px;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .15);
    transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
    direction: ltr;
    position: relative;
    z-index: 100;
    right: 0;
    margin-right: -250px;
}

.sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar .sidebar-content {
    background-color: var(--bodyBackgroundColor);
    transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
    padding: 1rem;
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0;
}


.sidebar.collapsed {
    margin-right: 0px;
}

.sidebar .sidebar-link {
    padding: 0.5rem;
    transition: color 75ms ease-in-out;
    position: relative;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
}

.sidebar .sidebar-link:hover,
.sidebar .sidebar-link.active {
    background-color: var(--primaryColor);
    color: var(--primaryColorText);

}

.sidebar .sidebar-link svg {
    width: 25px;
}

.sidebar .sidebar-link span {
    flex: 1 1;
}

.sidebar .sidebar-dropdown .sidebar-link {
    padding: 0.55rem 1.5rem 0.55rem 3.65rem;
}


.sidebar .sidebar-item {
    display: block;
    transition: color 75ms ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.sidebar .sidebar-nav {
    padding-left: 0;
    list-style: none;
}

@media only screen and (max-width: 768px) {
    .sidebar {
        position: fixed;
    }

    .sidebar.collapsed:before {
        content: "";
        background: rgba(0, 0, 0, .325);
        position: fixed;
        left: 0;
        top: 0;
        top: 70px;
        height: calc(100% - 120px);
        width: 100%;
        z-index: -1;
        pointer-events: none;
    }

}

/* Footer */
.footer {
    display: none;
    width: 100%;
    z-index: 100;
    position: fixed;
    background: var(--navBackgroundColor) !important;
    padding: 0.5rem 1rem 0.5rem 1rem;
    height: 60px;
    box-shadow: 0 -0.25rem 0.5rem rgba(0, 0, 0, .15);
    color: var(--navColorText);
}

.footer .nav-item {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.footer .nav-item .underline {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 10px;
    background: var(--primaryColor);
}


.footer .nav-item {
    cursor: pointer;
}

.footer .nav-item span:hover,
.footer .nav-item span.active,
.footer .nav-item a:hover,
.footer .nav-item a.active {
    color: var(--primaryColor);

}

.footer .nav {
    align-items: center;
}

.footer ul {
    width: 100%;
}

@media only screen and (max-width: 768px) {

    .footer {
        display: flex;
    }
}

/* TOKEN */
@media screen and (max-width: 500px) {

    .tokenButton:focus,
    .tokenButton {
        font-size: 15px !important;
        width: 35px !important;
        height: 35px !important;
    }
}

.tokenButton:focus,
.tokenButton {
    padding: 0.25rem;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--primaryColorText);
    margin-left: 5px !important;
    margin-right: 5px !important;
    text-align: center;
    font-size: 20px;
    width: 45px;
    height: 45px;
    border-radius: 5px;
}

//Loader

.pageLoaderContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

//Gallery

.projectVideoContainer:last-of-type {
    margin-bottom: 35px;
}

.projectVideoContainer {
    max-width: 1280px;
    max-height: 720px;
    cursor: pointer;
    margin-top: 35px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

// Events
.eventContainer {

    .event-card .card-body {
        display: flex
    }

    .event-card .card-body .vertically-centered:first-child {
        padding-right: var(--bs-card-spacer-x);
    }

    .event-card .card-body div:last-child {
        flex: 1;
    }

    .pageHeaderDays .card:not(:last-of-type) {
        margin-right: 1rem;
    }

    .interestButtonGroup .btn-outline-secondary:focus-visible,
    .interestButtonGroup .btn-outline-secondary.active,
    .interestButtonGroup .btn-outline-secondary:active,
    .interestButtonGroup .btn-outline-secondary:focus,
    .interestButtonGroup .btn-outline-secondary:hover,
    .interestButtonGroup .btn-outline-secondary {
        color: var(--secondaryColor) !important;
        background-color: var(--secondaryColorText) !important;
    }

    .interestButtonGroup .btn-secondary:focus-visible,
    .interestButtonGroup .btn-secondary.active,
    .interestButtonGroup .btn-secondary:active,
    .interestButtonGroup .btn-secondary:focus,
    .interestButtonGroup .btn-secondary:hover,
    .interestButtonGroup .btn-secondary {
        color: var(--secondaryColorText) !important;
        background-color: var(--secondaryColor) !important;
    }
}

// Speaker
.speakerContainer {

    .swiper-slide {
        max-width: 180px;
    }

    .speakerItemHeader {
        position: relative;
        height: 125px;
        background-color: var(--primaryColor);
        border-radius: 5px;
    }

    .speakerRankingContainer {
        position: absolute;
        right: 11px;
        top: 7px;
        color: var(--primaryColorText);
    }

    .speakerItemHeader img {
        height: 175px;
        width: 140px;
        border: 5px solid var(--primaryColorText);
        position: absolute;
        right: calc(50% - 70px);
        top: 45px;
        left: calc(50% - 70px);
        border-radius: 5px;
        object-fit: cover;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    .speakerItemBody {
        position: relative;
        text-align: center;
    }

    .speakerItemBody .col-sm-12:first-child {
        margin-top: 6.75rem;
    }
}

.speaker-card .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.speaker-card .card-body div:last-child {
    flex: 1;
}

.speaker-card .speakerProfile {
    width: 100% !important;
    height: 210px;
    object-fit: cover;
    border-radius: 10px !important;
    border: 1px solid var(--bs-border-color);
}

// Points of interest
.pointsOfInterestContainer {

    @media only screen and (max-width: 587px) {

        .pointsOfInterestCard .card-body:not(:first-of-type) {
            padding-left: 3rem !important;
            padding-right: 3rem !important;
        }
    }

    .imageContainer {
        position: relative;
        width: 100%;
        padding-top: 100%;
        border-radius: 50%;
        border: 1px solid var(--borderColor);
    }

    .imageContainer img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        z-index: index 2;
    }

    .imageContainer.not-collected img {
        filter: grayscale(100%) blur(2px);
    }

    .pointsOfInterestDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}

.pointsOfInterestCardQr {

    .imageContainer {
        position: relative;
        width: 300px;
        height: 300px;
        padding-top: 100%;
        border-radius: 50%;
        border: 1px solid var(--borderColor);
    }

    .imageContainer img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        z-index: index 2;
    }

    .qrcodeItemBody {
        justify-content: center;
        display: flex;
    }

    .qrcodeItemCard {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .pointsOfInterestDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}

// Networking

.networkingContainer {

    .header-wrapper {
        margin: auto;
        display: flex;
        position: relative;
        width: 100%;
        height: 45px;
        background-color: var(--primaryColorText);
        border: 2px solid var(--primaryColor);
        border-radius: 25px;

        .tab-wrapper {
            display: flex;
            position: relative;
            width: 100%;
            border-radius: 25px;
        }

        .tab-button {
            flex: 1;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            position: relative;
            z-index: 1;
            color: var(--primaryColor);
            transition: color 0.3s;
            padding: 0.5rem 0.25rem;
        }

        .tab-button.active {
            color: #fff;
        }

        .switch-background {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            color: var(--primaryColorText);
            background-color: var(--primaryColor);
            border-radius: 25px;
            z-index: 0;
            width: 33.34%;
            height: 100%;
        }
    }

    .swiper-grid-column>.swiper-wrapper {
        flex-direction: unset !important;
    }

    .swiper-slide {
        margin-top: 0rem !important;
    }

    .swiper-wrapper {
        padding-bottom: 1.25rem;
    }

    .networkingItemHeader {
        position: relative;
        height: 125px;
        background-color: var(--primaryColor);
        border-radius: 5px;
    }

    .networkingItemHeader label {
        height: 140px;
        width: 140px;
        border: 5px solid var(--primaryColorText);
        position: absolute;
        right: calc(50% - 70px);
        top: 45px;
        left: calc(50% - 70px);
        border-radius: 5px;
        object-fit: cover;
        display: flex;
        justify-content: center;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    .networkingItemHeader .cameraIcon {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
        z-index: 2;
        width: 40px;
        height: 40px;
        font-size: 1rem;
        position: absolute;
        bottom: -20px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .networkingRankingLeft {
        position: absolute;
        left: 10px;
        top: 10px;
        color: var(--primaryColorText);
    }

    .networkingRankingRight {
        position: absolute;
        right: 10px;
        top: 10px;
        color: var(--primaryColorText);
    }

    .networkingItemHeader img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-color: var(--bodyBackgroundColor);
    }

    .networkingItemBody {
        max-width: 450px;
        margin: auto;
        text-align: center;
        position: relative;
    }

    .networkingItemBody .col-sm-12:first-child {
        margin-top: 4.5rem;
        position: relative;
    }

    .buttonAdd {
        width: 35px;
        height: 35px;
    }
}

.networking-card .dropdown-toggle {
    background-color: transparent !important;
    border: 0px !important;
    color: inherit !important;
}

.networking-card .dropdown-toggle::after {
    display: none !important;
}

.networking-card .userRelationshipButton {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.networking-card .card-body {
    display: flex;
}

.networking-card .networkingFields {
    flex-grow: 1;
    width: 100%;
    padding-right: var(--bs-card-spacer-x);
    padding-left: var(--bs-card-spacer-x);
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.networking-card .networkingProfile {
    width: 60px !important;
    height: 60px !important;
    object-fit: cover;
    border-radius: 10px !important;
    border: 1px solid var(--bs-border-color);
}

.networking-card .networkingCollectionProfile {
    width: 100% !important;
    height: 200px !important;
    object-fit: cover;
    border-radius: 10px !important;
    border: 1px solid var(--bs-border-color);
}

//Notifications
.notificationContainer.dropdown-menu {
    padding-top: 0rem !important;
}

.notificationContainer {

    .readPoint {
        position: absolute;
        background-color: var(--primaryColor);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        left: 4px;
        opacity: 75;
    }

    .notificationButtons {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .notificationDropdownIcon {
        width: 25px;
    }

    .dropdown-toggle {
        background-color: transparent !important;
        border: 0px !important;
        color: inherit !important;
    }

    .dropdown-toggle::after {
        display: none !important;
    }

    img {
        width: 60px !important;
        height: 60px !important;
        object-fit: cover;
        border-radius: 10px !important;
        border: 1px solid var(--bs-border-color);
    }

    .message {
        padding-right: var(--bs-card-spacer-x);
        padding-left: var(--bs-card-spacer-x);
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
        width: 100%;
    }

    .message .timeText {
        font-size: 0.775rem;

    }

    .input-group-text {
        cursor: pointer;
    }

    .notification-card-header {
        justify-content: space-between;
        align-items: center;
        display: flex;
        border-bottom: 1px solid var(--bs-border-color);
        padding: 0.75rem;
    }

    .notification-card-body {
        overflow-y: auto;
        width: 100%;
        height: 400px;
        width: 400px;
    }

    .notification-card-footer {
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
        border-top: 1px solid var(--bs-border-color);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        text-align: center;
        padding: 0.5rem;
        cursor: pointer;
    }
}

//Ranking
.rankingContainer {

    .header-wrapper {
        margin: auto;
        display: flex;
        position: relative;
        width: 100%;
        height: 45px;
        background-color: var(--primaryColorText);
        border: 2px solid var(--primaryColor);
        border-radius: 25px;

        .tab-wrapper {
            display: flex;
            position: relative;
            width: 100%;
            border-radius: 25px;
        }

        .tab-button {
            flex: 1;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            position: relative;
            z-index: 1;
            color: var(--primaryColor);
            transition: color 0.3s;
            padding: 0.5rem 0.25rem;
        }

        .tab-button.active {
            color: #fff;
        }

        .switch-background {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            color: var(--primaryColorText);
            background-color: var(--primaryColor);
            border-radius: 25px;
            z-index: 0;
            width: 50%;
            height: 100%;
        }
    }

    .podium {
        margin: 0rem auto 1.5rem auto;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: auto;
        max-width: 500px;
    }

    .spot {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.33%;
        justify-content: flex-end;
        height: 250px;
        position: relative;
    }

    .cardProfile {
        position: absolute;
        width: 100%;
        padding: 0rem 0.5rem;
    }

    .place {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primaryColor);
        position: relative;
        width: 100%;
        filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
        color: var(--primaryColorText);
        font-size: 25px;
    }

    .first {
        .place {
            height: 60px;
        }

        .cardProfile {
            bottom: 70px;
        }
    }

    .second {
        .place {
            height: 45px;
        }

        .cardProfile {
            bottom: 55px;
        }
    }

    .third {
        .place {
            height: 35px;
        }

        .cardProfile {
            bottom: 45px;
        }
    }

    .cardProfile .card {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 175px;
    }


    .side-element {
        flex-shrink: 0;
    }

    .topRankingPics {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 10px !important;
        border: 1px solid var(--bs-border-color);
    }

    .achievementCard::before {
        position: absolute;
        top: 17px;
        right: -0.5rem;
        content: "";
        background-color: var(--secondaryColor);
        height: 28px;
        width: 28px;
        transform: rotate(45deg);
    }

    .notCollected::before,
    .notCollected::after {
        display: none;
    }

    .achievementCard::after {
        border-radius: 2px 0px 0px 2px;
        position: absolute;
        content: attr(data-label);
        top: 11px;
        right: -14px;
        width: 6rem;
        font-size: 14px;
        background-color: var(--primaryColor);
        color: white;
        text-align: center;
        font-family: "Roboto", sans-serif;
        box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    }

    .achievementCard {
        position: relative;

        .shining-border {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            pointer-events: none;
            z-index: 1;
            overflow: hidden;
        }

        .shining-border::before {
            content: '';
            position: absolute;
            top: -50%;
            left: -100%;
            width: 400%;
            height: 200%;
            background: linear-gradient(120deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.01));
            transform: rotate(45deg);
            animation: shining 2s linear infinite;
        }

        @keyframes shining {
            0% {
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }

            100% {
                transform: translateX(50%) translateY(50%) rotate(45deg);
            }
        }

        .card-body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
        }

        .achievement-img {
            width: 45px;
            height: 45px;
            object-fit: cover;
            border-radius: 50%;
            border: 1px solid var(--bs-border-color);
        }

        .message {
            flex-grow: 1;
            padding-left: var(--bs-card-spacer-x);
            flex-grow: 1;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-grow: 1;
            width: 100%;
        }
    }

    .rankingCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 0.5rem 0.75rem;

        img {
            width: 45px;
            height: 45px;
            object-fit: cover;
            border-radius: 10px !important;
            border: 1px solid var(--bs-border-color);
        }

        .message {
            flex-grow: 1;
            padding-right: var(--bs-card-spacer-x);
            padding-left: var(--bs-card-spacer-x);
            flex-grow: 1;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-grow: 1;
            width: 100%;
        }
    }
}

//Exhibitors
.exhibitorContainer {

    .swiper-slide {
        max-width: 300px;
    }

    .card-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .card-img,
    .card-img-top,
    .card-img-bottom {
        object-fit: contain;
        height: 180px;
        border-bottom: 1px solid var(--borderColor);
    }

    .exhibitorItemHeader {
        position: relative;
        height: 125px;
        background-color: var(--primaryColor);
        border-radius: 5px;
    }

    .exhibitorRankingContainer {
        position: absolute;
        right: 11px;
        top: 7px;
        color: var(--primaryColorText);
    }

    .exhibitorItemHeader img {
        height: 140px;
        width: 140px;
        border: 5px solid var(--primaryColorText);
        position: absolute;
        right: calc(50% - 70px);
        top: 45px;
        left: calc(50% - 70px);
        border-radius: 5px;
        object-fit: contain;
        background-color: var(--primaryColorText);
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    .exhibitorItemBody {
        position: relative;
        text-align: center;
    }

    .exhibitorItemBody .col-sm-12:first-child {
        margin-top: 4.5rem;
    }

}

// Sponsors
.sponsorContainer {

    .swiper-slide {
        max-width: 250px;
    }

    .card-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .card-img,
    .card-img-top,
    .card-img-bottom {
        object-fit: cover;
        height: 180px;
    }

    .sponsorItemHeader {
        position: relative;
        height: 125px;
        background-color: var(--primaryColor);
        border-radius: 5px;
    }

    .sponsorItemHeader img {
        height: 140px;
        width: 140px;
        border: 5px solid var(--primaryColorText);
        position: absolute;
        right: calc(50% - 70px);
        top: 45px;
        left: calc(50% - 70px);
        border-radius: 5px;
        object-fit: cover;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    .sponsorItemBody {
        text-align: center;
    }

    .sponsorItemBody .col-sm-12:first-child {
        margin-top: 4.5rem;
    }

}

// QRCode
.qrcodeContainer {

    .tab-content {
        height: calc(100% - 45px);
    }

    .tab-pane {
        height: 100%;
    }

    .myqrcodecard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    @media only screen and (min-width: 778px) {

        .qrcode-frame01,
        .qrcode-frame02 {
            max-width: 350px !important;
        }

        .qrcode-feedback {
            max-width: 400px;
        }
    }

    .qrcode-frame01 {
        padding: 1rem;
        max-width: 250px;
        margin: 2rem;
        background-color: var(--primaryColor);
        border-radius: 10px;
    }

    .qrcode-frame02 {
        max-width: 250px;
        margin: 2rem;
        background-color: var(--primaryColor);
        border-radius: 10px;
    }

    .qrcode-feedback {
        margin-top: 1rem;
        max-width: 300px;
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
        border-radius: 10px;
        height: 40px;
        width: 100%;
    }
}

// QRCode modal
.qrcodeModal {
    .qrcodeItemBody {
        display: flex;
    }

    .qrcodeItemCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .perfil {
        width: 100px;
        height: 100px;
        object-fit: cover;
        cursor: pointer;
        border: 1px solid var(--bs-border-color)
    }
}

//Home
.homeContainer {

    .banner img {
        width: 100%;
        height: 350px;
        object-fit: cover;
    }

    @media only screen and (max-width: 768px) {

        .banner img {
            width: 100%;
            height: 225px;
            object-fit: cover;
        }
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        margin-bottom: 1rem;
    }

}

//Perfil
.perfilContainer {
    .networkingItemBody {
        max-width: 100%;
    }
}

// Events
.eventContainer {
    .header-wrapper {
        margin: auto;
        display: flex;
        position: relative;
        width: 100%;
        height: 45px;
        background-color: var(--primaryColorText);
        border: 2px solid var(--primaryColor);
        border-radius: 25px;

        .tab-wrapper {
            display: flex;
            position: relative;
            width: 100%;
            border-radius: 25px;
        }

        .tab-button {
            flex: 1;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            position: relative;
            z-index: 1;
            color: var(--primaryColor);
            transition: color 0.3s;
            padding: 0.5rem 0.25rem;
        }

        .tab-button.active {
            color: #fff;
        }

        .switch-background {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            color: var(--primaryColorText);
            background-color: var(--primaryColor);
            border-radius: 25px;
            z-index: 0;
            width: 50%;
            height: 100%;
        }
    }

    .card-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .menuScroll {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
    }

    .swiper-slide {
        max-width: 300px;
    }

    .qrcodeContainer .card-img,
    .card-img-top,
    .card-img-bottom {
        object-fit: cover;
        max-height: 200px;
    }

    .eventItemHeader {
        position: relative;
        height: 125px;
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
        border-radius: 5px;
    }

    .eventItemCalendar {
        position: relative;
        display: inline-block;
    }

    .eventItemCalendar svg {
        color: var(--primaryColor);
        width: 55px;
        height: 55px;
    }

    .eventItemCalendar span {
        font-size: 1.5rem;
        color: var(--primaryColorText);
        font-weight: bold;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .fileIcon {
        height: 40px;
        width: 40px;
    }

    .videoFallback {
        max-width: 1080px;
        max-height: 720px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: black;
    }

    .eventItemTitle {
        background-color: var(--primaryColor);
        border-radius: 25px;
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        align-items: center;
        margin-bottom: 1rem;
    }

    .eventItemDetails {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    .selectedDate {
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
    }

    .menuScroll .card {
        margin-right: var(--bs-card-spacer-x);
    }
}

.event-card {

    .dateBorder {
        color: inherit;
        border: 0;
        border-left: 1px solid;
        opacity: 0.25;
        padding-right: var(--bs-card-spacer-x);
    }

    .btn-sm {
        border: 1px solid (var(--secondaryColor)) !important;
        --bs-btn-padding-y: 0.20rem;
        --bs-btn-padding-x: 0.4rem;
        --bs-btn-font-size: 0.775rem;
        --bs-btn-border-radius: 0.25rem;
    }

    .speakers img {
        margin-right: 0.5rem;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
    }
}

//Galeria
.galleryContainer {

    .ReactGridGallery_tile-bottom-bar span {
        color: #fff !important;
    }
}

//Tutorial
.tutorialContainer {
    padding: 1.5rem;
    display: flex;
    height: 100%;
    flex-direction: column;

    .tutorialSlideController {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .tutorialSlideController div {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: var(--bodyColorText);
        cursor: pointer;
    }

    .tutorial-body-center {
        max-width: 650px;
        margin: auto;
    }

    .tutorialSkip {
        text-decoration: underline;
    }

    .tutorialSlideController :not(:last-child) {
        margin-right: 10px;
    }

    .tutorialTitleRound {
        background-color: var(--primaryColor);
        padding: 0.75rem 1rem;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        color: var(--primaryColorText);
        border-radius: 50%;
        align-items: center;
        font-size: 1.5rem;
    }

    .tutorialBackground {
        background-color: var(--bs-modal-bg);
    }
}

.tutorialContainerProfile {
    height: 100%;
    overflow: auto;
    position: relative;

    .tutorial-body-center {
        max-width: 650px;
        margin: auto;
    }

    .profileTitle {
        padding: 1.5rem;
        color: var(--primaryColorText);
        z-index: 2;
    }

    .profileHeader {
        position: relative;
        height: 175px;
        background-color: var(--primaryColor);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .profileHeader label {
        height: 140px;
        width: 140px;
        border: 5px solid var(--primaryColorText);
        position: absolute;
        right: calc(50% - 70px);
        top: 110px;
        left: calc(50% - 70px);
        border-radius: 5px;
        object-fit: cover;
        display: flex;
        justify-content: center;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    .profileHeader .cameraIcon {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
        z-index: 2;
        width: 40px;
        height: 40px;
        font-size: 1rem;
        position: absolute;
        bottom: -20px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .profileHeader img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-color: var(--bodyBackgroundColor);
    }

    .profileBody {
        padding: 1.5rem;
        text-align: center;
    }

    .profileBody .col-sm-12:first-child {
        margin-top: 4.5rem;
    }
}

.leadCollectionContainer {

    .header-wrapper {
        margin: auto;
        display: flex;
        position: relative;
        width: 100%;
        height: 45px;
        background-color: transparent;
        border: 2px solid var(--primaryColor);
        border-radius: 25px;

        .tab-wrapper {
            display: flex;
            position: relative;
            width: 100%;
            border-radius: 25px;
        }

        .tab-button.active{
            color: var(--primaryColorText);
        }

        .tab-button {
            flex: 1;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            position: relative;
            z-index: 1;
            color: var(--primaryColor);
            transition: color 0.3s;
            padding: 0.5rem 0.25rem;
        }

        .switch-background {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            color: var(--primaryColorText);
            background-color: var(--primaryColor);
            border-radius: 25px;
            z-index: 0;
            width: 50%;
            height: 100%;
        }
    }


    .networkingItemHeader {
        position: relative;
        height: 125px;
        background-color: var(--primaryColor);
        border-radius: 5px;
    }

    .networkingItemHeader label {
        height: 140px;
        width: 140px;
        border: 5px solid var(--primaryColorText);
        position: absolute;
        right: calc(50% - 70px);
        top: 45px;
        left: calc(50% - 70px);
        border-radius: 5px;
        object-fit: cover;
        display: flex;
        justify-content: center;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    .networkingItemHeader .cameraIcon {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        background-color: var(--primaryColor);
        color: var(--primaryColorText);
        z-index: 2;
        width: 40px;
        height: 40px;
        font-size: 1rem;
        position: absolute;
        bottom: -20px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .networkingRankingLeft {
        position: absolute;
        left: 10px;
        top: 10px;
        color: var(--primaryColorText);
    }

    .networkingRankingRight {
        position: absolute;
        right: 10px;
        top: 10px;
        color: var(--primaryColorText);
    }

    .networkingItemHeader img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-color: var(--bodyBackgroundColor);
    }

    .networkingItemBody {
        max-width: 450px;
        margin: auto;
        text-align: center;
        position: relative;
    }

    .networkingItemBody .col-sm-12:first-child {
        margin-top: 4.5rem;
        position: relative;
    }

}

.checkbox-col {
    flex: 0 0;
}

.storysetImages {
    min-height: 250px;
    height: 250px;
}

.image-gallery-thumbnail:hover,
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    border: 2px solid var(--primaryColor) !important;
    border-radius: 2px;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    text-align: left !important;
}

.youtube-video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    /* 16:9 Aspect Ratio */
    height: 0;
}

.not-collected {
    filter: grayscale(100%);
    opacity: 0.75;
}

.youtube-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.swiper-pagination-bullet-active {
    background-color: var(--primaryColor) !important;
    opacity: 1 !important;
}

.tab-content {
    padding: 0rem 0.75rem;
}

.swiper-pagination-bullet {
    background-color: var(--bodyColorText);
    opacity: 0.5;
}

.swiper-button-prev,
.swiper-button-next {
    color: var(--primaryColor) !important;
}
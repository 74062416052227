/* notyf */
@keyframes notyf-fadeinup {
    0% {
        opacity: 0;
        transform: translateY(25%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes notyf-fadeinleft {
    0% {
        opacity: 0;
        transform: translateX(25%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes notyf-fadeoutright {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(25%);
    }
}

@keyframes notyf-fadeoutdown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(25%);
    }
}

@keyframes ripple {
    0% {
        transform: scale(0) translateY(-45%) translateX(13%);
    }

    to {
        transform: scale(1) translateY(-45%) translateX(13%);
    }
}

.notyf {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    pointer-events: none;
    box-sizing: border-box;
    padding: 20px;
}

.notyf__icon--error,
.notyf__icon--success {
    height: 21px;
    width: 21px;
    background: #fff;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    position: relative;
}

.notyf__icon--error:after,
.notyf__icon--error:before {
    content: "";
    background: currentColor;
    display: block;
    position: absolute;
    width: 3px;
    border-radius: 3px;
    left: 9px;
    height: 12px;
    top: 5px;
}

.notyf__icon--error:after {
    transform: rotate(-45deg);
}

.notyf__icon--error:before {
    transform: rotate(45deg);
}

.notyf__icon--success:after,
.notyf__icon--success:before {
    content: "";
    background: currentColor;
    display: block;
    position: absolute;
    width: 3px;
    border-radius: 3px;
}

.notyf__icon--success:after {
    height: 6px;
    transform: rotate(-45deg);
    top: 9px;
    left: 6px;
}

.notyf__icon--success:before {
    height: 11px;
    transform: rotate(45deg);
    top: 5px;
    left: 10px;
}

.notyf__toast {
    display: block;
    overflow: hidden;
    pointer-events: auto;
    animation: notyf-fadeinup 0.3s ease-in forwards;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    padding: 0 15px;
    border-radius: 2px;
    max-width: 250px;
    transform: translateY(25%);
    box-sizing: border-box;
    flex-shrink: 0;
}

.notyf__toast--disappear {
    transform: translateY(0);
    animation: notyf-fadeoutdown 0.3s forwards;
    animation-delay: 0.25s;
}

.notyf__toast--disappear .notyf__icon,
.notyf__toast--disappear .notyf__message {
    animation: notyf-fadeoutdown 0.3s forwards;
    opacity: 1;
    transform: translateY(0);
}

.notyf__toast--disappear .notyf__dismiss {
    animation: notyf-fadeoutright 0.3s forwards;
    opacity: 1;
    transform: translateX(0);
}

.notyf__toast--disappear .notyf__message {
    animation-delay: 0.05s;
}

.notyf__toast--upper {
    margin-bottom: 20px;
}

.notyf__toast--lower {
    margin-top: 20px;
}

.notyf__toast--dismissible .notyf__wrapper {
    padding-right: 30px;
}

.notyf__ripple {
    height: 400px;
    width: 400px;
    position: absolute;
    transform-origin: bottom right;
    right: 0;
    top: 0;
    border-radius: 50%;
    transform: scale(0) translateY(-51%) translateX(13%);
    z-index: 5;
    animation: ripple 0.4s ease-out forwards;
}

.notyf__wrapper {
    display: flex;
    align-items: center;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 15px;
    border-radius: 3px;
    position: relative;
    z-index: 10;
}

.notyf__icon {
    width: 22px;
    text-align: center;
    font-size: 1.3em;
    opacity: 0;
    animation: notyf-fadeinup 0.3s forwards;
    animation-delay: 0.3s;
    margin-right: 13px;
}

.notyf__dismiss {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 26px;
    margin-right: -15px;
    animation: notyf-fadeinleft 0.3s forwards;
    animation-delay: 0.35s;
    opacity: 0;
}

.notyf__dismiss-btn {
    background-color: rgba(0, 0, 0, 0.25);
    border: none;
    cursor: pointer;
    transition: opacity 0.2s ease, background-color 0.2s ease;
    outline: none;
    opacity: 0.35;
    height: 100%;
    width: 100%;
}

.notyf__dismiss-btn:after,
.notyf__dismiss-btn:before {
    content: "";
    background: #fff;
    height: 12px;
    width: 2px;
    border-radius: 3px;
    position: absolute;
    left: calc(50% - 1px);
    top: calc(50% - 5px);
}

.notyf__dismiss-btn:after {
    transform: rotate(-45deg);
}

.notyf__dismiss-btn:before {
    transform: rotate(45deg);
}

.notyf__dismiss-btn:hover {
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.15);
}

.notyf__dismiss-btn:active {
    opacity: 0.8;
}

.notyf__message {
    font-size: 0.95rem;
    vertical-align: middle;
    position: relative;
    opacity: 0;
    animation: notyf-fadeinup 0.3s forwards;
    animation-delay: 0.25s;
    line-height: 1.5em;
}

@media only screen and (max-width: 480px) {
    .notyf {
        padding: 0;
    }

    .notyf__ripple {
        height: 600px;
        width: 600px;
        animation-duration: 0.5s;
    }

    .notyf__toast {
        max-width: none;
        border-radius: 0;
        box-shadow: 0 -2px 7px 0 rgba(0, 0, 0, 0.13);
        width: 100%;
    }

    .notyf__dismiss {
        width: 56px;
    }
}

/* Quiz timer beat */
@keyframes timer_beat {

    40%,
    80% {
        transform: none;
    }

    50% {
        transform: scale(1.125);
    }
}

@keyframes timer_countdown {
    0% {
        content: "3";
    }

    33% {
        content: "2";
    }

    66% {
        content: "1";
    }

    100% {
        content: "0";
    }
}

@keyframes timer_indicator {
    100% {
        transform: translateY(100%);
    }
}


@keyframes ldio-fe05nnalsmc {
    0% {
        transform: translate(6px, 40px) scale(0);
    }

    25% {
        transform: translate(6px, 40px) scale(0);
    }

    50% {
        transform: translate(6px, 40px) scale(1);
    }

    75% {
        transform: translate(40px, 40px) scale(1);
    }

    100% {
        transform: translate(74px, 40px) scale(1);
    }
}

@keyframes ldio-fe05nnalsmc-r {
    0% {
        transform: translate(74px, 40px) scale(1);
    }

    100% {
        transform: translate(74px, 40px) scale(0);
    }
}

@keyframes ldio-fe05nnalsmc-c {
    0% {
        background: #ffffff
    }

    25% {
        background: #ffffff
    }

    50% {
        background: #ffffff
    }

    75% {
        background: #ffffff
    }

    100% {
        background: #ffffff
    }
}

.ldio-fe05nnalsmc div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(40px, 40px) scale(1);
    background: #ffffff;
    animation: ldio-fe05nnalsmc 1.7543859649122806s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-fe05nnalsmc div:nth-child(1) {
    background: #ffffff;
    transform: translate(74px, 40px) scale(1);
    animation: ldio-fe05nnalsmc-r 0.43859649122807015s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-fe05nnalsmc-c 1.7543859649122806s infinite step-start;
}

.ldio-fe05nnalsmc div:nth-child(2) {
    animation-delay: -0.43859649122807015s;
    background: #ffffff;
}

.ldio-fe05nnalsmc div:nth-child(3) {
    animation-delay: -0.8771929824561403s;
    background: #ffffff;
}

.ldio-fe05nnalsmc div:nth-child(4) {
    animation-delay: -1.3157894736842104s;
    background: #ffffff;
}

.ldio-fe05nnalsmc div:nth-child(5) {
    animation-delay: -1.7543859649122806s;
    background: #ffffff;
}

.loadingio-spinner-ellipsis-gicmspgtc0w {
    width: 91px;
    height: 91px;
    display: inline-block;
    overflow: hidden;
    background: none;
}

.ldio-fe05nnalsmc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.91);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-fe05nnalsmc div {
    box-sizing: content-box;
}